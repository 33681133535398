export const loginFromScoutID = async () => {
	const request = await fetch("https://scoutid.se/jwt/jwt.php?app_id=scoutstuga.se", {
		credentials: 'include',
		method: 'GET'
	});

	const result = await request.json();

	if(!result || !result.ok || !result.token) {
		return result;
	}

	return result;
};

export const translateToken = async (token: string) => {
	const request = await fetch('https://scoutid.se/api/whoami',{
		method: 'GET',
		headers: {
			authorization: `Bearer ${token}`
		}
	});

	return await request.json();
}
