import React from "react";
import { Card, makeStyles, CardMedia, CardContent, Typography, Grid, Button, Theme, Chip } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import Accommodation from "../models/Accommodation";
import AmenitiesSection from "./AmenitiesSection";
import { accommodationTypeToString, regionToString } from "../models/Types";
import { useTranslation } from "react-i18next";
import PlaceIcon from '@material-ui/icons/Place';

const useStyles = makeStyles((theme: Theme) => ({
    root:{
        width: '100%',
        marginTop: 25,
        marginBottom: 25,
    },
    card: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        background: '#ffffff',
        position: 'relative'
    },
    cardMedia: {
      width: '100%',
      height: '50%'
    },
    clickableHeading: {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    cardContent: {
      position: 'relative'
    },
    actionArea: {
        height: '100%',
    },
    listingState: {
        height: 50,
        padding: '0 1rem',
        color: "#ffffff",
        position: 'absolute',
        top: '1rem',
        left: '1rem'
    },
    placeText: {
      display: 'flex',
      alignItems: 'center'
    },
    typeChip: {
      position: 'absolute',
      top: '-18px',
      right: '10px',
    }
}));

interface Props{
    accommodation: Accommodation;
    onClick: Function;
}

const AccommodationCard = (props: Props) => {
    const classes = useStyles();
    const [t] = useTranslation('common');
    const theme = useTheme();

    return <React.Fragment>
        <Card className={classes.card} elevation={0}>
            {(props.accommodation.deactivated || !props.accommodation.approved) && <Card 
                className={classes.listingState} 
                style={{ 
                  backgroundColor: props.accommodation.deactivated ? theme.palette.error.main : theme.palette.secondary.main, 
                }} 
                elevation={15}
            >
              <Grid container style={{ width: '100%', height: '100%' }} alignItems='center' justify='center' >
                <Typography variant="body2" style={{ textAlign: 'center', fontWeight: 'bold' }}>
                  {props.accommodation.deactivated ? 'Deactivated' : 'Pending approval'}
                </Typography>
              </Grid>
            </Card>}

            <CardMedia 
                className={classes.cardMedia} 
                component='img' 
                src={props.accommodation.mainPhoto} />
            <CardContent className={classes.cardContent}>
                <Chip label={t(accommodationTypeToString(props.accommodation.type))} color="primary" className={classes.typeChip} />
                <Typography variant="h3" color="primary" onClick={(_) => props.onClick()} className={classes.clickableHeading}>{props.accommodation.name}</Typography>
                <p className={classes.placeText}><PlaceIcon color="primary" fontSize="inherit" /> {regionToString(props.accommodation.region)}</p>
                <AmenitiesSection accommodation={props.accommodation} showShortList={true} />
                <Button color="primary" onClick={(_) => props.onClick()}>Läs mer</Button>
            </CardContent>
        </Card>
      </React.Fragment>
}

export default AccommodationCard;