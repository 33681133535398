import React, { Fragment } from "react";
import { makeStyles, Grid, Typography, Link, Box } from "@material-ui/core";
import Accommodation from "../models/Accommodation";
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import PublicIcon from '@material-ui/icons/Public';
import ScoutSymbol from '../assets/customIcons/scoutSymbol';

const useStyles = makeStyles({
    contactInfo: {
      marginBottom: '.25rem'
    },
    iconContainer: {
      height: '1.25rem',
      width: '1.25rem',
      borderRadius: '50%',
      color: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '.5rem'
    },
    scoutImg: {
      width: '0.9rem'
    }
});

interface Props {
    accommodation: Accommodation,
}

const InformationSection = (props: Props) => {
    const classes = useStyles();

    const createIcon = (icon: any) => {
      return <Box bgcolor="primary.main" className={classes.iconContainer}>{icon}</Box>
    }

    return (
      <Fragment>
        <Typography variant="h4">{props.accommodation.contactInfo.name}</Typography>
        {props.accommodation.contactInfo.owner !== undefined &&
          <Grid
            key='owner'
            container
            direction='row'
            className={classes.contactInfo}>
            { createIcon(<ScoutSymbol color="#ffffff" fontSize="0.9rem" />) }
            <Typography>{props.accommodation.contactInfo.owner}</Typography>
          </Grid>
        }

        {props.accommodation.contactInfo.email !== undefined &&
          <Grid
            key='email'
            container
            direction='row'
            className={classes.contactInfo}
          >
            { createIcon(<EmailIcon fontSize="inherit" />) }
            <Link href={`mailto:${props.accommodation.contactInfo.email}`} underline="always">
              {props.accommodation.contactInfo.email}
            </Link>
          </Grid>
        }
        {props.accommodation.contactInfo.phone !== undefined &&
          <Grid
            key='phone'
            container
            direction='row'
            className={classes.contactInfo}
          >
            { createIcon(<PhoneIcon fontSize="inherit" />) }
            <Typography>{props.accommodation.contactInfo.phone}</Typography>
          </Grid>
        }
        {props.accommodation.contactInfo.url !== undefined &&
          <Grid
            key='url'
            container
            direction='row'
            className={classes.contactInfo}>
            { createIcon(<PublicIcon fontSize="inherit" />) }
            <Link href={props.accommodation.contactInfo.url} underline="always">
              {props.accommodation.contactInfo.url}
            </Link>
          </Grid>
        }
      </Fragment>)
}

export default InformationSection;