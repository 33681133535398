import React, { useEffect, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  makeStyles,
  Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

// Icons
import PersonIcon from '@material-ui/icons/Person';
import HotelIcon from '@material-ui/icons/Hotel';
import SingleBedIcon from '@material-ui/icons/SingleBed';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import PowerIcon from '@material-ui/icons/Power';
import WifiIcon from '@material-ui/icons/Wifi';
import OpacityIcon from '@material-ui/icons/Opacity';
import PetsIcon from '@material-ui/icons/Pets';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import AccessibleIcon from '@material-ui/icons/Accessible';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import DirectionsTransitIcon from '@material-ui/icons/DirectionsTransit';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import WcIcon from '@material-ui/icons/Wc';
import Campfire from '../assets/customIcons/campfire';
import FloorSpace from '../assets/customIcons/floorspace';
import Showers from '../assets/customIcons/showers';
import Sauna from '../assets/customIcons/sauna';

// Utils
import { $enum } from 'ts-enum-util';

// Models
import Accommodation from '../models/Accommodation';
import { WaterType, waterTypeToString } from '../models/Types';

const useStyles = makeStyles({
    amenitiesContainer: {
        marginBottom: 20,
        '& p': {
          marginBottom: 0
        }
    },
    listItemIcon: (props: StyleProps) => ({
      color: props.color,
      marginRight: 10
    }),
    bathroomIcon: {
      fontWeight: 700,
      fontFamily: 'arial',
      fontSize: '100%',
      width: 24,
      paddingTop: 2
    }
});

interface StyleProps{
    color: string;
}

interface Props {
    accommodation: Accommodation;
    showAll?: boolean;
    showShortList?: boolean;
}

const AmenitiesSection = (props: Props) => {
    const theme = useTheme();
    const classes = useStyles({color: props.showShortList ? theme.palette.primary.main : '#FFFFFF'});
    const [amenityList, setAmenityList] = useState<Array<JSX.Element[]>>([]);
    const [t] = useTranslation('common');

    useEffect(()=>{
        const getAmenities = () => {
            let amenitiesInfo: JSX.Element[] = [];
            let amenitiesInfoColumn2: JSX.Element[] = [];
            const amenities = props.accommodation.amenities;
            
            const guestText = amenities.guests > 1 ? t('guests') : t('guest');
            amenitiesInfo.push(
              <Grid key="guests" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center' }}>
                <PersonIcon className={classes.listItemIcon} />
                <p>{`${amenities.guests} ${guestText}`}</p>
              </Grid>
            )
    
            if(amenities.bedrooms > 0){
              const bedroomText = amenities.bedrooms > 1 ? t('bedrooms') : t('bedroom');
              amenitiesInfo.push(
                <Grid key="bedrooms" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                  <HotelIcon className={classes.listItemIcon} />
                  <p>{`${amenities.bedrooms} ${bedroomText}`}</p>
                </Grid>
              );
            }
    
            if(amenities.beds > 0){
              amenitiesInfo.push(
                <Grid key="beds" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                  <SingleBedIcon className={classes.listItemIcon} />
                  <p>{`${amenities.beds} ${t('beds')}`}</p>
                </Grid>
              );
            }
    
            if(amenities.floorSpaces > 0){
              amenitiesInfo.push(
                <Grid key="floorspaces" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                  <FloorSpace color={props.showShortList ? theme.palette.primary.main : "#ffffff"} className={classes.listItemIcon} />
                  <p>{`${props.accommodation.amenities.floorSpaces} ${t('floor_spaces')}`}</p>
                </Grid>
              );
            }
    
            if(amenities.kitchens > 0){
              const kitchenText = amenities.kitchens > 1 ? t('kitchens') : t('kitchen');
              amenitiesInfo.push(
                <Grid key="kitchens" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                  <RestaurantIcon className={classes.listItemIcon} />
                  <p>{`${amenities.kitchens} ${kitchenText}`}</p>
                </Grid>
              );
            }

            if(amenities.bathrooms.length > 0){
              const bathroomText = amenities.bathrooms.length > 1 ? t('bathrooms') : t('bathroom');

              amenitiesInfo.push(
                <Grid key="bathrooms" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                  <WcIcon className={classes.listItemIcon} />
                  <p>{`${amenities.bathrooms.length} ${bathroomText}`}</p>
                </Grid>
              );

              if(!props.showShortList) {
                let numOfWC: number[] = [];
                let numOfTC: number[] = [];

                amenities.bathrooms.forEach(type => {
                  if(type === 0) {
                    numOfWC.push(type);
                  } else {
                    numOfTC.push(type);
                  }
                });

                if(numOfWC.length > 0) {
                  amenitiesInfo.push(
                    <Grid key="wc" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                      <p className={`${classes.listItemIcon} ${classes.bathroomIcon}`}>WC</p>
                      <p>{numOfWC.length} WC</p>
                    </Grid>
                  );
                }

                if(numOfTC.length > 0) {
                  amenitiesInfo.push(
                    <Grid key="tc" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                      <p className={`${classes.listItemIcon} ${classes.bathroomIcon}`}>TC</p>
                      <p>{numOfTC.length} TC</p>
                    </Grid>
                  );
                }
              }
            }

            if(amenities.showers){
              amenitiesInfo.push(
                <Grid key="showers" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                  <Showers color={ props.showShortList ? theme.palette.primary.main : "#ffffff"} className={classes.listItemIcon} />
                  <p>{t('showers')}</p>
                </Grid>
              );
            }
   
            if(amenities.electricity){
              amenitiesInfo.push(
                <Grid key="electricity" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                  <PowerIcon className={classes.listItemIcon} />
                  <p>{t('electricity')}</p>
                </Grid>
              );
            }
    
            if(amenities.wifi){
              amenitiesInfo.push(
                <Grid key="wifi" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                  <WifiIcon className={classes.listItemIcon} />
                  <p>{t('wifi')}</p>
                </Grid>
              );
            }
            
            if(amenities.water !== WaterType.None){
              let waterText;
              $enum(WaterType).forEach(type => {
                  if(amenities.water === type){
                    waterText = t(waterTypeToString(type));
                  }
              });
              
              amenitiesInfo.push(
                <Grid key="water" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                  <OpacityIcon className={classes.listItemIcon} />
                  <p>{waterText}</p>
                </Grid>
              );
            }

            if(amenities.parking){
              amenitiesInfo.push(
                <Grid key="parking" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                  <LocalParkingIcon className={classes.listItemIcon} />
                  <p>{t('parking')}</p>
                </Grid>
              );
            }

            if(amenities.handicapAdapted){
              amenitiesInfo.push(
                <Grid key="handicap" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                  <AccessibleIcon className={classes.listItemIcon} />
                  <p>{t('handicap_adapted')}</p>
                </Grid>
              );
            }

            if(amenities.campfire){
              amenitiesInfo.push(
                <Grid key="campfire" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                  <Campfire color={ props.showShortList ? theme.palette.primary.main : "#ffffff"} className={classes.listItemIcon} />
                  <p>{t('campfire')}</p>
                </Grid>
              );
            }

            if(amenities.publicBeach){
              amenitiesInfo.push(
                <Grid key="beach" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                  <BeachAccessIcon className={classes.listItemIcon} />
                  <p>{t('public_beach')}</p>
                </Grid>
              );
            }

            if(amenities.sauna){
              amenitiesInfo.push(
                <Grid key="sauna" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                  <Sauna color={ props.showShortList ? theme.palette.primary.main : "#ffffff"} className={classes.listItemIcon} />
                  <p>{t('sauna')}</p>
                </Grid>
              );
            }

            if(amenities.petsAllowed){
              amenitiesInfo.push(
                <Grid key="pets" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                  <PetsIcon className={classes.listItemIcon} />
                  <p>{t('pets_allowed')}</p>
                </Grid>
              );
            }

            if(amenities.partyAllowed){
              amenitiesInfo.push(
                <Grid key="party" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                  <LocalBarIcon className={classes.listItemIcon} />
                  <p>{t('party_allowed')}</p>
                </Grid>
              );
            }

            amenitiesInfo.push(
              <Grid key="transport" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                <DirectionsTransitIcon className={classes.listItemIcon} />
                <p>{`${t('distance_to_public_transport_small')}: ${amenities.publicTransportDistance}km`}</p>
              </Grid>
            );
            
            amenitiesInfo.push(
              <Grid key="grocery" item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                <LocalGroceryStoreIcon className={classes.listItemIcon} />
                <p>{`${t('distance_to_grocery_store_small')}: ${amenities.groceryStoreDistance}km`}</p>
              </Grid>
            );

            if(amenities.otherAmenities && amenities.otherAmenities?.length > 0){
              amenities.otherAmenities.forEach((el)=>{
                if(props.showAll || amenitiesInfo.length < 21){
                  amenitiesInfo.push(
                    <Grid key={el} item xs={6} md={props.showShortList ? 6 : 4} lg={props.showShortList ? 6 : 12} style={{ display: 'flex', alignItems: 'center'}}>
                      <InfoOutlinedIcon className={classes.listItemIcon} />
                      <p>{el}</p>
                    </Grid>
                  );
                }
              });
            }   
    
            if(amenitiesInfo.length % 2 === 0){
                amenitiesInfoColumn2 = amenitiesInfo.splice(amenitiesInfo.length / 2, amenitiesInfo.length / 2);
            }
            else{
                amenitiesInfoColumn2 = amenitiesInfo.splice(amenitiesInfo.length / 2 + 1, amenitiesInfo.length / 2);
            }
    
            return [amenitiesInfo, amenitiesInfoColumn2];
        }

        setAmenityList(getAmenities());

        // eslint-disable-next-line
    }, []);

    const renderShortList = () => {
      const newAmenityList = [];

      if(amenityList.length > 0) {
        for(let i=0; i < 4; i++) {
          if(amenityList[0][i] !== undefined) {
            newAmenityList.push(amenityList[0][i]);
          }
        }
        return newAmenityList;
      }
    }
  
    return (
      <Fragment>
        <Grid container className={classes.amenitiesContainer}>
          { props.showShortList ? renderShortList() : amenityList }
        </Grid>
      </Fragment>
    )
}

export default AmenitiesSection;