import React, { useEffect } from 'react'
import { useRecoilState, useResetRecoilState } from 'recoil';
import { adminState } from '../store/admin_state';
import { hardReset } from '../store/hard_reset';
import { loadedAccommodations } from '../store/loaded_accommodations';
import { mapData } from '../store/map_data';
import { profileState } from '../store/profile_state';

const HardResetComponent = () => {
    const [reset, setReset] = useRecoilState(hardReset);
    const resetAdminAccommodations = useResetRecoilState(adminState);
    const resetHomeAccommodations = useResetRecoilState(loadedAccommodations);
    const resetMapAccommodations = useResetRecoilState(mapData);
    const resetProfileAccommodations = useResetRecoilState(profileState);

    useEffect(()=>{
        if(reset){
            resetAdminAccommodations();
            resetHomeAccommodations();
            resetMapAccommodations();
            resetProfileAccommodations();
            setReset(false);
        }

        // eslint-disable-next-line
    }, [reset]);

    return <></>;
}

export default HardResetComponent;