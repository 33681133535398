import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Accommodation from '../../models/Accommodation';
import { hardReset } from '../../store/hard_reset';
import { modalsState } from '../../store/modals_state';
import { editAccommodation } from '../../utils/database';

const ApproveModal = () => {
    const [modals, setModals] = useRecoilState(modalsState);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const setHardReset = useSetRecoilState(hardReset);
    const [t] = useTranslation('common');

    const handleClose = () => {
        setModals({
            ...modals,
            approveModal: null,
        });
    }

    const onApprove = () => {
        if(modals.approveModal){
            setLoading(true);
            let newAcc = Accommodation.clone(modals.approveModal);
            newAcc.approved = true;
            newAcc.edited = false;
            editAccommodation(newAcc).then(res => {
                setLoading(false);
                if(res){
                    setHardReset(true);
                    handleClose();
                }
                else{
                    setError(true);
                }
            });
        }
    }

    return <Dialog
        open={modals.approveModal !== null}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {t('approve_listing')}
        </DialogTitle>
        <DialogContent>
            {loading 
                ? <Grid container alignItems='center' justify='center'><CircularProgress /></Grid>
                : <DialogContentText id="alert-dialog-description">
                    {error ? t('basic_error') : t('approve_listing_message')}
            </DialogContentText>}
        </DialogContent>
        {!loading && <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('back')}
                </Button>
                <Button onClick={error ? handleClose : onApprove} color="primary">
                    OK
                </Button>
            </DialogActions>
        }
    </Dialog>
}

export default ApproveModal;