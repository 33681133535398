import React from 'react';
import { Grid } from '@material-ui/core';
import AccommodationScreen from '../../screens/AccommodationScreen';

const StepFive = () => {
    return <Grid>
        <AccommodationScreen preview />
    </Grid>
}

export default StepFive;