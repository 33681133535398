import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import useWindowDimensions from '../custom_hooks/useWindowDimensions';
import { modalsState } from '../store/modals_state';

const NoUserFallback = () => {
    const [modals, setModals] = useRecoilState(modalsState);
    const { height } = useWindowDimensions();
    const [t] = useTranslation('common');

    const onLogin = async () => {
        setModals({
            ...modals,
            loginModal: true,
        })
    }

    return <Grid 
        container 
        alignItems='center' 
        direction='column' 
        justify='center' 
        style={{ height: height - 50 }}
    >
        <Button variant="contained" color='secondary' onClick={onLogin}>{t('login')}</Button>
    </Grid>
}

export default NoUserFallback;