import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil';
import User from '../../models/User';
import { authUser } from '../../store/auth_user';
import { modalsState } from '../../store/modals_state';
import { getUser, saveUser } from '../../utils/database';
import { loginFromScoutID, translateToken } from '../../utils/requests';

const LoginModal = () => {
    const [modals, setModals] = useRecoilState(modalsState);
    const [user, setUser] = useRecoilState(authUser);

    useEffect(()=>{
        if(user.id === ''){
            loginFromScoutID().then(async (result)=>{
                if(result.token){
                    const userJson = await translateToken(result.token);
                    const newUser = await findUser(userJson)
                    if(newUser){
                        setUser(newUser);
                    }
                }
            });
        }
        
        
        if(modals.loginModal){
            loginFromScoutID().then(async (result)=>{
                if(result.token){
                    const userJson = await translateToken(result.token);
                    const newUser = await findUser(userJson)
                    if(newUser){
                        setUser(newUser);
                    }
                }
                else if(result.url){
					window.location = result.url;
                }
            });
            
            setModals({
                ...modals,
                loginModal: false,
            });
        }

        // eslint-disable-next-line
    }, [modals.loginModal]);

    const findUser = async (userJson: any) => {
        if(userJson.user){
            let userRes = await getUser(userJson.user.toString());
            let user;

            if(!userRes){
                console.log("1");
                const saved = await saveUser(new User({ 
                    id: userJson.user.toString(),
                    username: userJson.data.email.toString(),
                    isAdmin: userJson.data.roles.includes('organisation:692:employee_expanded'),
                    listings: [],
                }));
                console.log("2");
                if(saved){
                    user = new User(await getUser(userJson.user));
                }
            }
            else{
                user = new User(userRes);
            }
            
            return user;
        }
    }

    return <></>;
}

export default LoginModal;
