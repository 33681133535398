import { WaterType, ToiletType } from "./Types";

export default class Amenities{
    guests: number;
    bedrooms: number;
    beds: number;
    floorSpaces: number;
    kitchens: number;
    bathrooms: Array<ToiletType>;
    kitchenEquipment: string;
    water: WaterType;
    electricity: boolean;
    wifi: boolean;
    petsAllowed: boolean;
    partyAllowed: boolean;
    parking: boolean;
    handicapAdapted: boolean;
    publicBeach: boolean;
    campfire: boolean;
    showers: boolean;
    sauna: boolean;
    publicTransportDistance: number;
    groceryStoreDistance: number;
    otherAmenities?: Array<string>;
    more?: string;

    constructor(guests: number, bedrooms: number, beds: number, floorSpaces: number, kitchens: number, 
        bathrooms: Array<ToiletType>, water: WaterType, electricity: boolean, wifi: boolean, petsAllowed: boolean, 
        partyAllowed: boolean, parking: boolean, handicapAdapted: boolean, publicBeach: boolean, 
        campfire: boolean, showers: boolean, sauna: boolean, publicTransportDistance: number, groceryStoreDistance: number, 
        kitchenEquipment: string, otherAmenities?: Array<string>, more?: string){

        this.guests = guests;
        this.bedrooms = bedrooms;
        this.beds = beds;
        this.floorSpaces = floorSpaces;
        this.kitchens = kitchens;
        this.bathrooms = bathrooms;
        this.water = water;
        this.electricity = electricity;
        this.wifi = wifi;
        this.petsAllowed = petsAllowed;
        this.partyAllowed = partyAllowed;
        this.parking = parking;
        this.handicapAdapted = handicapAdapted;
        this.publicBeach = publicBeach;
        this.campfire = campfire;
        this.showers = showers ?? false;
        this.sauna = sauna ?? false;
        this.publicTransportDistance = publicTransportDistance;
        this.groceryStoreDistance = groceryStoreDistance;
        this.kitchenEquipment = kitchenEquipment;
        this.otherAmenities = otherAmenities ?? [];
        this.more = more ?? '';
    }
    
    static fromDbObject(obj: any): Amenities{
        const amenities = obj.amenities;
        return new Amenities(
            amenities.guests,
            amenities.bedrooms,
            amenities.beds,
            amenities.floorSpaces,
            amenities.kitchens,
            amenities.bathrooms,
            amenities.water,
            amenities.electricity,
            amenities.wifi,
            amenities.petsAllowed,
            amenities.partyAllowed,
            amenities.parking,
            amenities.handicapAdapted,
            amenities.publicBeach,
            amenities.campfire,
            amenities.showers,
            amenities.sauna,
            amenities.publicTransportDistance,
            amenities.groceryStoreDistance,
            amenities.kitchenEquipment,
            amenities.otherAmenities ? [...amenities.otherAmenities] : undefined,
            amenities.more,
        );
    };
}