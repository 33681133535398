import React, { useState, useEffect } from 'react';
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { 
    makeStyles, 
    FormGroup, 
    FormControlLabel, 
    Checkbox, 
    Grid, 
    Button, 
    Chip, 
    TextField,
    InputAdornment,
    Typography } from "@material-ui/core"
import { newListingState } from "../../store/new_listing_state";
import { typedKeys } from "../../utils/functions";

const useStyles = makeStyles({
    root: {
        padding: 10,
    },
    row: {
        display: 'flex', 
        justifyContent: 'start',
    },
    rowInput: {
        display: 'flex', 
        justifyContent: 'space-between',
        marginTop: 30,
    },
    checkboxContainer: {
      margin: 0,
    },
    checkbox: {
        paddingLeft: 0
    },
    input: {
        minWidth: 270,
        marginBottom: 40,
    },
    inputWithButton: {
        minWidth: 270,
    },
    chip: {
        marginLeft: 5,
        marginBottom: 5
    },
    chipContainer: {
        marginTop: 40,
    },
    textField: {
      marginBottom: 10
    },
    gridMargin: {
      marginBottom: '1.5rem'
    }
});

interface Props{
    allTouched: boolean;
}

const StepThree = (props: Props) => {
    const classes = useStyles();
    const [listingState, setListingState] = useRecoilState(newListingState);
    const [currentAmenity, setCurrentAmenity] = useState('');
    const [touched, setTouched] = useState({
        contact_name: props.allTouched,
        contact_owner: props.allTouched,
        contact_phone: props.allTouched,
        contact_email: props.allTouched,
        contact_url: false,
        publicTransportDistance: props.allTouched,
        groceryStoreDistance: props.allTouched
    });
    const [t] = useTranslation('common');

    useEffect(()=>{
        setTouched({
            contact_name: props.allTouched,
            contact_owner: props.allTouched,
            contact_phone: props.allTouched,
            contact_email: props.allTouched,
            contact_url: false,
            publicTransportDistance: props.allTouched,
            groceryStoreDistance: props.allTouched
        });
        
        setListingState({
            ...listingState,
            location: {...listingState.location},
            activeStepValid: checkValidity('contact_name', listingState.contact_name),
        });

        // eslint-disable-next-line
    }, [props.allTouched]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setListingState({ 
            ...listingState,
            location: {...listingState.location},
            [event.target.name]: event.target.checked,
        });
    }

    const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const valid = checkValidity(event.target.name, event.target.value);

        setListingState({
            ...listingState, 
            location: {...listingState.location},
            [event.target.name]: event.target.value,
            activeStepValid: valid 
        });
    }

    const onAmenityInput = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCurrentAmenity(event.target.value);
    }

    const onAmenitiesAdd = () => {
        const newAmenities = Array<string>()

        currentAmenity.split(',').forEach((newAmenity) => {
            if(newAmenity.trim().length > 0 && !listingState.otherAmenities.includes(newAmenity.trim())){
                newAmenities.push(newAmenity.trim());
            }
        });

        setListingState({ 
            ...listingState, 
            location:{...listingState.location}, 
            otherAmenities: [...listingState.otherAmenities, ...newAmenities],
        });
        setCurrentAmenity('');
    }

    const onAmenityDelete = (amenity: String) => {
        const filteredAmenities = listingState.otherAmenities.filter((item)=>item !== amenity);

        setListingState({ 
            ...listingState, 
            location:{...listingState.location}, 
            otherAmenities: filteredAmenities,
        });
    }

    const getCheckbox = (name: string, title: string) => {
        const key = typedKeys(listingState).filter(key=>key===name)[0];
        
        return <FormControlLabel
            className={classes.checkboxContainer}
            control={
                <Checkbox 
                    className={classes.checkbox}
                    checked={listingState[key] as boolean} 
                    onChange={handleCheckboxChange} 
                    name={name}
                    color="primary"
                />
            }
            label={title}
        />;
    }

    const amenitiesChips = ()=>{
        if(listingState.otherAmenities.length === 0){
            return null;
        }
        else{
            return listingState.otherAmenities.map(
                (amenity) => <Chip 
                    key={amenity}
                    label={amenity} 
                    onDelete={()=>onAmenityDelete(amenity)} 
                    color="primary"
                    className={classes.chip} />
            );
        }
    }

    const onTouch = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if(event.target.name !== 'contact_url'){
            setTouched({...touched, [event.target.name]: true});
        }
    }

    const checkValidity = (key: string, value: string) => {
        let valid = true;

        if(listingState.publicTransportDistance.trim().length === 0 || key === 'publicTransportDistance'){
            if(key === 'publicTransportDistance'){
                if(value.trim().length === 0){
                    return false;
                }
            }
            else{
                return false;
            }
        }

        if(listingState.groceryStoreDistance.trim().length === 0 || key === 'groceryStoreDistance'){
            if(key === 'groceryStoreDistance'){
                if(value.trim().length === 0){
                    return false;
                }
            }
            else{
                return false;
            }
        }

        if(listingState.contact_name.trim().length === 0 || key === 'contact_name'){
            if(key === 'contact_name'){
                if(value.trim().length === 0){
                    return false;
                }
            }
            else{
                return false;
            }
        }

        if(listingState.contact_owner.trim().length === 0 || key === 'contact_owner'){
          if(key === 'contact_owner'){
              if(value.trim().length === 0){
                  return false;
              }
          }
          else{
              return false;
          }
        }

        if(listingState.contact_phone.trim().length === 0 || key === 'contact_phone'){
            if(key === 'contact_phone'){
                if(value.trim().length === 0){
                    return false;
                }
            }
            else{
                return false;
            }
        }

        if(listingState.contact_email.trim().length === 0 || key === 'contact_email'){
            if(key === 'contact_email'){
                if(value.trim().length === 0){
                    return false;
                }
            }
            else{
                return false;
            }
        }

        return valid;
    };

    const input = (name: any, title: string, type: string, multiline?: boolean) => {
      const key = typedKeys(listingState).filter(key=>key===name)[0];
      const tKey = typedKeys(touched).filter(key=>key===name)[0];
      
      return <TextField
              className={classes.textField}
              placeholder={title}
              label={title}
              type={type}
              onBlur={onTouch} 
              name={name} 
              value={listingState[key].toString()}
              multiline={multiline} 
              rows={6}
              onChange={onInputChange}
              error={listingState[key].toString().trim().length === 0 && touched[tKey]}
              helperText={listingState[key].toString().trim().length === 0 && touched[tKey] ? `${title} ${t('is_required')}` : ' '}
              required
              fullWidth
            />
    }

    const numberInput = (name: any, title: string, required = false, inputAdornment?: string ) => {
      const key = typedKeys(listingState).filter(key=>key===name)[0];
      const tKey = typedKeys(touched).filter(key=>key===name)[0];
      
      return <TextField
        className={classes.textField}
        placeholder={title}
        label={title}
        type="number"
        onBlur={onTouch} 
        name={name} 
        value={key !== 'bathrooms' ? listingState[key].toString() 
        : listingState[key].length.toString()}
        onChange={onInputChange}
        error={listingState[key].toString().trim().length === 0 && touched[tKey]}
        helperText={listingState[key].toString().trim().length === 0 && touched[tKey] ? `${title} ${t('is_required')}` : ' '}
        required={required}
        fullWidth
        InputProps={inputAdornment ? {
          endAdornment: <InputAdornment position="end">{inputAdornment}</InputAdornment>,
        } : undefined}
      />;
    }
    
    return <div className={classes.root} >
        <FormGroup>
          <Grid container>
            <Grid container className={classes.gridMargin}>
              <Grid item xs={12} sm={6} lg={4} direction='column' style={{display: 'flex'}}>
                {getCheckbox('electricity', t('electricity'))}
                {getCheckbox('wifi', t('wifi'))}
                {getCheckbox('showers', t('showers'))}
                {getCheckbox('sauna', t('sauna'))}
              </Grid>
              <Grid item xs={6} sm={6} lg={4} direction='column' style={{display: 'flex'}}>
                {getCheckbox('campfire', t('campfire'))}
                {getCheckbox('publicBeach', t('public_beach'))}
                {getCheckbox('parking', t('parking'))}
                {getCheckbox('handicapAdapted', t('handicap_adapted'))}
              </Grid>
              <Grid item xs={12} md={6} lg={4} direction='column' style={{display: 'flex'}}>
                {getCheckbox('partyAllowed', t('party_allowed'))}
                {getCheckbox('petsAllowed', t('pets_allowed'))}
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.textField}>
              {amenitiesChips()}
            </Grid>
            <Grid container xs={12} spacing={2} className={classes.gridMargin}>
              <Grid item xs={6}>
                <TextField
                  placeholder={t('more_amenities_ex')}
                  label={t('more_amenities')}
                  name='more' 
                  value={currentAmenity}
                  onChange={onAmenityInput}
                  fullWidth
                />
              </Grid>
              <Grid item xs alignItems="flex-end" style={{display: 'flex'}}>
                <Button color='primary' variant="contained" onClick={onAmenitiesAdd} disableElevation>
                  {t('add')}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                label={t('other')}
                onBlur={onTouch} 
                name='more'
                value={listingState.more}
                multiline
                rows={4}
                onChange={onInputChange}
                helperText=' '
                fullWidth
              />
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                {numberInput('publicTransportDistance', `${t('distance_to_public_transport')}`, true, 'km')}
              </Grid>
              <Grid item xs={12} md={6}>
                {numberInput('groceryStoreDistance', `${t('distance_to_grocery_store')}`, true, 'km')}
              </Grid>
            </Grid>

            <Typography align='left' variant='h3' component="h2">
                {t('contact_information')}
            </Typography>
            <Grid item xs={12}>
              {input("contact_name", t('name'), "text")}
            </Grid>
            <Grid item xs={12}>
              {input("contact_owner", t('owner'), "text")}
            </Grid>
            <Grid item xs={12}>
              {input("contact_phone", t('phone'), "text")}
            </Grid>
            <Grid item xs={12}>
              {input("contact_email", t('email'), "email")}
            </Grid>
            <Grid item xs={12}>
              {input("contact_url", t('url'), "text")}
            </Grid>
          </Grid>
        </FormGroup>
    </div>
}

export default StepThree;