import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import createPalette from '@material-ui/core/styles/createPalette';
import { fade } from '@material-ui/core/styles/colorManipulator';

const customBreakpointValues = {
  values: {
    xs: 0,
    sm: 400,
    md: 768,
    lg: 990,
    xl: 1200,
  },
}

const customPaletteValues = {
  primary: {
    light: '#1666A4',
    main: '#003660',
    dark: '#002441',
    contrastText: '#FFFFFF'
  },
  secondary: {
    light: '#A6451B',
    main: '#D14800',
    dark: '#8C3712',
    contrastText: '#FFFFFF'
  },
  error: {
    main: '#DA005E',
    dark: '#820038'
  },
  info: {
    main: '#CCD7DF',
    contrastText: '#003660'
  },
  text: {
    primary: '#444547',
  }
}

const breakpoints = createBreakpoints({ ...customBreakpointValues });
const palette = createPalette({ ...customPaletteValues })

const theme = createMuiTheme({
  palette: {
    ...customPaletteValues,
  },
  typography: {
    fontFamily: ["TeeFranklin","Roboto"].join(","),
    h1: {
      fontSize: '2.1875rem',
      [breakpoints.up('sm')]: {
        fontSize: '2.5rem',
      },
      [breakpoints.up('md')]: {
        fontSize: '2.8125rem',
      },
      [breakpoints.up('lg')]: {
        fontSize: '3.4375rem',
      },
      [breakpoints.up('xl')]: {
        fontSize: '4.0625rem',
        marginBottom: '1.25rem',
      }
    },
    h2: {
      fontSize: '1.5625rem',
      marginBottom: '1.75rem',
      color: palette.text.primary,
      [breakpoints.up('sm')]: {
        fontSize: '1.875rem',
      },
      [breakpoints.up('md')]: {
        fontSize: '2.1875rem',
      },
      [breakpoints.up('lg')]: {
        fontSize: '2.5rem',
      },
      [breakpoints.up('xl')]: {
        fontSize: '2.8125rem',
      }
    },
    h3: {
      fontSize: '1.25rem',
      marginBottom: '0.25rem',
      [breakpoints.up('sm')]: {
        fontSize: '1.375rem',
      },
      [breakpoints.up('md')]: {
        fontSize: '1.5625rem',
        marginBottom: '0.5rem',
      },
      [breakpoints.up('xl')]: {
        fontSize: '1.875rem',
      }
    },
    h4: {
      fontSize: '1.125rem',
      marginBottom: '0.5rem',
      fontWeight: 500
    },
    subtitle2: {
      fontSize: '0.9375rem',
      color: palette.text.primary,
      fontWeight: 700
    }
  },
  breakpoints: {
    ...customBreakpointValues,
  },
  overrides: {
    MuiLink: {
      root: {
        "&:hover": {
          color: palette.primary.light,
        }
      }
    },
    MuiFormLabel: {
      root: {
        fontWeight: 700,
        fontSize: '0.9375rem',
        color: palette.text.primary,
        '&$focused': {
          color: palette.text.primary,
        }
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 0px) scale(1)',
        '&$shrink': {
          transform: 'translate(14px, 0px) scale(1)',
        },  
      },
      shrink: {
        transform: 'translate(14px, 0px) scale(1)',
      },
      asterisk: {
        color: palette.error.main,
      }
    },
    MuiOutlinedInput: {
      root: {
        marginTop: '1.3rem',
        background: '#F2F2EE',
        minHeight: '3.125rem',
        borderRadius: '10px',
        [breakpoints.up('xl')]: {
          minHeight: '3.75rem',
        },
        '&$focused $notchedOutline': {
          borderColor: '#444547',
          borderWidth: '1px',
        },
        '&$error': {
          background: fade(palette.error.main, 0.1),
        },
        '& legend': {
          width: 0,
        }  
      },
      input: {
        minHeight: '3.125rem',
        boxSizing: 'border-box',
        [breakpoints.up('xl')]: {
          minHeight: '3.75rem',
        }
      },
      notchedOutline: {
        borderColor: '#e6e6dd',
        transition: 'all 0.3s ease'
      },
    },
    MuiButton: {
      root: {
        height: '3.125rem',
        padding: '0 16px',
        borderRadius: '10px',
        fontWeight: 700,
        letterSpacing: '1px',
        lineHeight: 1.4,
        [breakpoints.up('xl')]: {
          height: '3.75rem',
        }
      },
      contained: {
        '&$disabled': {
          backgroundColor: palette.primary.main,
          opacity: 0.4,  
          color: 'white',
        },
      },
      containedSizeSmall: {
        padding: '0 24px',
        height: '2.5rem'
      },
      outlinedPrimary: {
        backgroundColor: '#CCD7DF',
        color: palette.primary.main,
        border: 'none',
        "&:hover": {
          backgroundColor: '#E4EBF0',
          border: 'none'
        },
        '&$disabled': {
          backgroundColor: '#CCD7DF',
          color: palette.primary.main,
          opacity: 0.4,
          border: 'none'
        },
      },
      outlinedSizeSmall: {
        padding: '0 24px',
        height: '2.5rem'
      },
      textPrimary: {
        height: 'auto',
        textDecoration: 'underline',
        "&:hover": {
          backgroundColor: 'transparent',
          color: palette.primary.light,
          textDecoration: 'underline',
        }
      },
      containedPrimary: {
        "&:hover": {
          backgroundColor: palette.primary.light,
        }
      }
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: 'transparent',
          color: palette.primary.main,
        }
      },
      colorPrimary: {
        "&:hover": {
          backgroundColor: 'transparent',
        }
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        "&$checked": {
          "&:hover": {
            backgroundColor: 'transparent',
          }  
        }
      }
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 0
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: '1rem',
        marginRight: '.75rem'
      }
    },
    MuiStepper: {
      root: {
        padding: 0
      }
    },
    MuiStepIcon: {
      root: {
        width: '3rem',
        height: '3rem',
        color: palette.info.main,
        zIndex: 1,
        [breakpoints.down('xs')]: {
          width: '2rem',
          height: '2rem',
        }
      },
    },
    MuiStepConnector: {
      alternativeLabel: {
        top: '1.5rem',
        [breakpoints.down('xs')]: {
          top: '1rem',
          left: 'calc(-50% + 15px)',
          right: 'calc(-50% + 15px)',
          width: '3rem'
        }
      },
      lineHorizontal: {
        borderColor: palette.info.main,
        borderTopWidth: 4,
      },
      active: {
        '& $line': {
          borderColor: palette.primary.main,
        }
      },
      completed: {
        '& $line': {
          borderColor: palette.primary.main,
        }
      }
    },
    MuiStepLabel: {
      labelContainer: {
        [breakpoints.down('xs')]: {
          display: 'none'
        }
      }
    },
    MuiTabs: {
      indicator: {
        top: 0,
        height: '4px'  
      }
    },
    MuiTab: {
      root: {
        fontSize: '1rem',
        fontWeight: 'bold',
        background: '#e6e6dd',
        width: '50%',
        maxWidth: '100%',
        '&$selected': {
          background: '#ffffff',
          color: palette.primary.main
        },
        [breakpoints.up('xl')]: {
          maxWidth: 264
        },  
      },
      wrapper: {
        flexDirection: 'row'
      },
      labelIcon: {
        minHeight: 'auto',
        height: '3.75rem'
      },
    }
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
    },
    MuiInputLabel: {
      shrink: true,
      disableAnimation: true
    },
  },
});

export default theme;