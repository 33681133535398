import { CosmosClient } from '@azure/cosmos'
import Accommodation from '../models/Accommodation';
import Location from '../models/Location';
import User from '../models/User';
import { deleteImages, saveImages } from './storage';

export const getDatabase = async () => {
    const endpoint = process.env.REACT_APP_COSMOSDB_ENDPOINT ?? '';
    const key = process.env.REACT_APP_COSMOSDB_KEY ?? '';
    const client = new CosmosClient({ endpoint, key });

    const { database } = await client.databases.createIfNotExists({ id: 'scoutstuga' });

    return database;
}

export const addListingToUser = async (userId: string, listingId: string) => {
    const user = await getUser(userId);

    if(user){
        const listings = [...user.listings, listingId];

        const usersContainer = (await getDatabase()).container('users');
        const result = await usersContainer.item(userId).replace({
            id: userId,
            username: userId,
            listings: [...listings],
            isAdmin: user.isAdmin,
        });

        return result.statusCode < 300;
    }

    return false;
}

export const deleteListingFromUser = async (userId: string, listingId: string) => {
    const user = await getUser(userId);

    if(user){
        const updatedListings = user.listings.filter(function(listing:String){ 
            return listing !== listingId;
        });
        const usersContainer = (await getDatabase()).container('users');
        const result = await usersContainer.item(userId).replace({
            id: userId,
            username: userId,
            listings: updatedListings,
            isAdmin: user.isAdmin,
        });

        return result.statusCode < 300;
    }

    return false;
}

export const saveUser = async (user: User) => {
    const usersContainer = (await getDatabase()).container('users');
    const userItemResponse = await usersContainer.items.create(user);
        
    if(userItemResponse.statusCode === 201){
        return true;
    }

    return false;
}

export const getUser = async (userId: string) => {
    const usersContainer = (await getDatabase()).container('users');
    const userItemResponse = await usersContainer.item(userId).read()

    if(userItemResponse.statusCode === 200){
        return await userItemResponse.resource;
    }
    else{
        return null;
    }
}

export const getUsers = async () => {
    const usersContainer = (await getDatabase()).container('users');
    return (await usersContainer.items.readAll().fetchAll()).resources;
} 

export const saveAccommodation = async (accommodation: Accommodation, userId: string, images: Array<File>): Promise<boolean> => {
    const result = await saveImages(accommodation.id, images);

    if(result){
        let accommodationToSave = Accommodation.clone(accommodation);
        accommodationToSave.mainPhoto = result[0];

        let otherPhotos = [...result];
        otherPhotos.splice(0, 1);
        accommodationToSave.photos = [...otherPhotos]

        if(await saveLocation(new Location(accommodation.id, accommodation.location.lat, accommodation.location.lng))){
            const accommodationsContainer = (await (await getDatabase()).containers.createIfNotExists({id: 'accommodations'})).container;
            const accommodationItemResponse = await accommodationsContainer.items.create(accommodationToSave);
        
            if(accommodationItemResponse.statusCode === 201){
                addListingToUser(userId, accommodation.id);
                return true;
            }
        }
    }

    return false;
}

export const editAccommodation = async (accommodation: Accommodation, mainImage?: Array<File>, otherImages?: Array<File>, imagesToDelete?: string[]) => {
    let resultMainImg: Array<string> | null = null;
    let resultOtherImg: Array<string> | null = null;

    if(imagesToDelete && imagesToDelete.length > 0) {
        const filteredImagesToDelete = imagesToDelete.filter((image) => {
            return image.includes(accommodation.id) === true;
        });
    
        let deleted = await deleteImages(filteredImagesToDelete);

        if(!deleted){
            return false;
        }
    }

    if((mainImage && mainImage.length > 0) || (otherImages && otherImages.length > 0)){
        const accommodationsContainer = (await (await getDatabase()).containers.createIfNotExists({id: 'accommodations'})).container;
        const realAcc = Accommodation.fromDbObject((await accommodationsContainer.item(accommodation.id).read()).resource);
        let deletedMainImage = false;

        if((mainImage && mainImage.length > 0) && (otherImages && otherImages.length > 0)){
            deletedMainImage = await deleteImages([realAcc.mainPhoto]);
            resultMainImg = await saveImages(accommodation.id, mainImage);
            resultOtherImg = await saveImages(accommodation.id, otherImages);

            if(!deletedMainImage || !resultMainImg || !resultOtherImg){
                return false;
            }    
        } else if((mainImage && mainImage.length > 0) && (otherImages && otherImages.length === 0)) {
            deletedMainImage = await deleteImages([realAcc.mainPhoto]);
            resultMainImg = await saveImages(accommodation.id, mainImage);
            
            if(!deletedMainImage || !resultMainImg){
                return false;
            }    
        } else if((mainImage && mainImage.length === 0) && (otherImages && otherImages.length > 0)) {
            resultOtherImg = await saveImages(accommodation.id, otherImages, true);

            if(!resultOtherImg){
                return false;
            }    
        } else {
            return false;
        }
    }

    if(await editLocation(new Location(accommodation.id, accommodation.location.lat, accommodation.location.lng))){
        let accToSave = Accommodation.clone(accommodation);
        if(resultMainImg || resultOtherImg){
            if(resultMainImg && resultOtherImg){
                accToSave.mainPhoto = resultMainImg[0];

                let otherPhotos = [...resultOtherImg];

                // Remove the local file path from accomodation object
                const filteredPhotos = accToSave.photos.filter((photo) => {
                    return photo.includes('blob:') === false;
                });

                accToSave.photos = [...filteredPhotos, ...otherPhotos];
            }
            else if(resultMainImg && resultOtherImg === null){
                accToSave.mainPhoto = resultMainImg[0];
            }
            else if(resultMainImg === null && resultOtherImg){
                let otherPhotos = [...resultOtherImg];
                
                // Remove the local file path from accomodation object
                const filteredPhotos = accToSave.photos.filter((photo) => {
                    return photo.includes('blob:') === false;
                });

                accToSave.photos = [...filteredPhotos, ...otherPhotos];
            }
        }
        
        const accommodationsContainer = (await (await getDatabase()).containers.createIfNotExists({id: 'accommodations'})).container;
        const result = await accommodationsContainer.item(accommodation.id).replace(accToSave);
    
        return result.statusCode < 300;
    }

    return false;
}

export const deleteAccommodation = async (id: string) => {    
    const accommodationsContainer = (await (await getDatabase()).containers.createIfNotExists({id: 'accommodations'})).container;
    const resultItem = accommodationsContainer.item(id);
    const acc = Accommodation.fromDbObject((await resultItem.read()).resource);

    if(await deleteImages([acc.mainPhoto, ...acc.photos])){
        if(await deleteLocation(id)){
            const deleteAccommodation = await resultItem.delete();
            const deleteAccommodationSuccess = deleteAccommodation.statusCode === 204 ? true : false;

            if(deleteAccommodationSuccess) {
                const users = await getUsers();
                const ownersOfListing = users.filter(user => {
                    return user.listings.includes(id);
                }).map(user => {
                    return user.id;
                });
            
                if(ownersOfListing.length > 0) {
                    let deleteListingFromUserIsSuccess = true;

                    for(let i = 0; i < ownersOfListing.length; i++) {
                        const ownerId = ownersOfListing[i];

                        if(ownerId !== undefined) {
                            const deleteFromUserResult = await deleteListingFromUser(ownerId, id);
                            
                            if(!deleteFromUserResult) {
                                deleteListingFromUserIsSuccess = false;
                            }
                        }
                    }

                    return deleteListingFromUserIsSuccess;
                }
            }
            return deleteAccommodation.statusCode === 204;
        }
    }

    return false;
}

export const getAllAccommodationsQuery = async (query: string, maxItemCount: number) => {
    const accommodationsContainer = (await (await getDatabase()).containers.createIfNotExists({id: 'accommodations'})).container;
    return accommodationsContainer.items.query(query, {maxItemCount: maxItemCount});
}

export const getAccommodation = async (id: string) => {
    const accommodationsContainer = (await (await getDatabase()).containers.createIfNotExists({id: 'accommodations'})).container;
    return (await accommodationsContainer.item(id).read()).resource;
}

export const saveLocation = async (location: Location) => {
    const locationsContainer = (await (await getDatabase()).containers.createIfNotExists({id: 'locations'})).container;
    const locationItemResponse = await locationsContainer.items.create(location)

    return locationItemResponse.statusCode === 201;
}

export const editLocation = async (location: Location) => {
    const locationsContainer = (await (await getDatabase()).containers.createIfNotExists({id: 'locations'})).container;
    const result = await locationsContainer.item(location.id).replace(location);

    return result.statusCode < 300;
}

export const deleteLocation = async (id: string) => {
    const locationsContainer = (await (await getDatabase()).containers.createIfNotExists({id: 'locations'})).container;
    const result = await locationsContainer.item(id).delete();

    return result.statusCode === 204;
}

export const getAllLocationsQuery = async () => {
    const locationsContainer = (await (await getDatabase()).containers.createIfNotExists({id: 'locations'})).container;
    return locationsContainer.items.readAll();
}