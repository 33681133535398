import React from 'react';
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme/theme';
import './App.css';

// Screens
import HomeScreen from './screens/HomeScreen';
import AccommodationScreen from './screens/AccommodationScreen';
import ProfileScreen from './screens/ProfileScreen';
import NewOrEditAccommodationScreen from './screens/NewOrEditAccommodationScreen';

// Components
import ScrollToTop from './components/ScrollToTop';
import LoginModal from './components/modals/LoginModal';
import NewOrEditListingModal from './components/modals/NewOrEditListingModal';
import ToggleActivationModal from './components/modals/ToggleActivationModal';
import DeleteModal from './components/modals/DeleteModal';
import ApproveModal from './components/modals/ApproveModal';
import HardResetComponent from './components/HardResetComponent';
import CoownersModal from './components/modals/CoownersModal';

function App() {
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <ScrollToTop />
        <LoginModal />
        <NewOrEditListingModal />
        <ToggleActivationModal />
        <ApproveModal />
        <DeleteModal />
        <HardResetComponent />
        <CoownersModal />
        <Switch>
          <Route path='/' exact component={HomeScreen} />
          <Route path='/profile' exact component={ProfileScreen} />
          <Route path='/accommodation/new' exact component={NewOrEditAccommodationScreen} />
          <Route path='/accommodation/:id/:name' exact component={AccommodationScreen} />
          <Route path='/accommodation/:id/:name/edit' exact component={NewOrEditAccommodationScreen} />
          <Redirect from='/home' to='/' />
        </Switch>
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

export default App;
