import React, { useState, useEffect, ChangeEvent, Fragment } from 'react';
import { 
    makeStyles,
    Grid, 
    Checkbox, 
    Button,
    TextField,
    Theme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { filtersState } from '../store/filters_state';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Region, regionToString } from '../models/Types';
import { $enum } from 'ts-enum-util';

interface Props{
    open: boolean,
    toggleDrawer: Function,
}

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        width: 400,
        padding: '2rem 3rem',
        [theme.breakpoints.down('sm')]: {
          width: '100vw',
          padding: '2rem 2rem',
        }
    },
    title: {
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: 10,
    },
    subtitle: {
        textAlign: 'left',
        fontWeight: 'bold',
        marginBottom: 20,
    },
    selection: {
        marginBottom: 20
    },
    button: {
      marginTop: '1.3rem',
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      }
    },
    inputRoot: {
      padding: 0,
      '&[class*="MuiOutlinedInput-root"]': {
        padding: '0 0 0 9px'
      }
    }
}));

const CustomDrawer = (props: Props) => {
    const classes = useStyles();
    const [filters, setFilters] = useRecoilState(filtersState);
    const [formState, setFormState] = useState(filters);
    const [ t ] = useTranslation('common');

    useEffect(()=>{
        setFormState(filters);
    }, [filters]);

    const toggleDrawer = (event: React.MouseEvent) => {
      setFormState(filters);
      props.toggleDrawer();
    };
    
    const handleFieldChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFormState({...formState, [event.target.name ?? '']: event.target.value});
    }

    const onSearch = () => {
      setFilters(formState);
    }

    const clearRegions = (event: ChangeEvent<{}>, value: any[]) => {
      const defaultRegionList = new Array<boolean>($enum(Region).length).fill(false);

      if(value.length < 1) {
        setFormState({ 
          ...formState, 
          regions: [...defaultRegionList]
        });
      } else {
        value.forEach(region => { defaultRegionList[region.value] = true; });
        setFormState({ 
          ...formState, 
          regions: [...defaultRegionList]
        });
      }
    }

    const regionList = () => {
      let region: any[] = [];
      $enum(Region).forEach(key=>{
        region.push({region: regionToString(key), value: key})
      });

      return region;
    }

    return <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
                multiple
                classes={{ inputRoot: classes.inputRoot }}
                options={regionList()}
                onChange={clearRegions}
                disableCloseOnSelect
                limitTags={1}
                getOptionLabel={(option) => option.region}
                renderOption={(option) => (
                  <React.Fragment>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      name={`region/${option.value}`}
                      checked={formState.regions[option.value]}
                    />
                    {option.region}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField {...params} label={t('region')} placeholder={t('region')} aria-readonly />
                )}
              />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              placeholder={t('freeSearch')}
              label={t('search')}
              name="general"
              value={formState.general}
              onChange={handleFieldChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2} style={{display: 'flex', alignItems: 'flex-start'}}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSearch}
              className={classes.button}
              fullWidth
              disableElevation>{t('search')}</Button>
          </Grid>
          <Grid item xs={12} md={2} style={{display: 'flex', alignItems: 'flex-start'}}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              className={classes.button}
              onClick={toggleDrawer}
              disableElevation>{t('more_filters')}</Button>
          </Grid>
        </Grid>
    </Fragment>
}

export default CustomDrawer;