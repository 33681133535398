import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { $enum } from "ts-enum-util";
import { 
    FormGroup, 
    makeStyles, 
    Typography,
    TextField,
    MenuItem } from '@material-ui/core';
import MapComponent from '../MapComponent';
import { newListingState } from '../../store/new_listing_state';
import { typedKeys } from '../../utils/functions';
import { AccommodationType, Region, regionToString, accommodationTypeToString } from '../../models/Types';

interface Props{
    allTouched: boolean;
}

const useStyles = makeStyles({
    root: {
        padding: 10,
    },
    textField: {
      marginBottom: 10
    },
    input: {
        marginBottom: 40,
        minWidth: 270,
    },
    map: {
        height: 350,
        padding: 10,
        marginBottom: 20,
    },
    locationText: (error: boolean)=>({
        padding: 10,
        color: error ? 'red' : 'black'
    }),
});

const StepOne = (props: Props) => {
    const [listingState, setListingState] = useRecoilState(newListingState);
    const [touched, setTouched] = useState({
        title: props.allTouched,
        description: props.allTouched,
        map: props.allTouched,
        price: props.allTouched,
        surroundings: props.allTouched,
        route: props.allTouched,
    });
    const classes = useStyles(touched.map && (!listingState.location.lat || !listingState.location.lng));
    const [t] = useTranslation('common');

    useEffect(()=>{
        setTouched({
            title: props.allTouched,
            description: props.allTouched,
            map: props.allTouched,
            price: props.allTouched,
            surroundings: props.allTouched,
            route: props.allTouched,
        });

        setListingState({
            ...listingState,
            location: {...listingState.location},
            activeStepValid: checkValidity('title', listingState.title),
        });

        // eslint-disable-next-line
    }, [props.allTouched]);

    const checkValidity = (key: string, value: any) => {
        if(listingState.title.trim().length === 0 || key === 'title'){
            if(key === 'title'){
                if(value.trim().length === 0){
                    return false;
                }
            }
            else{
                return false;
            }
        }
        if(listingState.description.trim().length === 0 || key === 'description'){
            if(key === 'description'){
                if(value.trim().length === 0){
                    return false;
                }
            }
            else{
                return false;
            }
        }
        if(listingState.price.trim().length === 0 || key === 'price'){
            if(key === 'price'){
                if(value.trim().length === 0){
                    return false;
                }
            }
            else{
                return false;
            }
        }
        if(listingState.surroundings.trim().length === 0 || key === 'surroundings'){
            if(key === 'surroundings'){
                if(value.trim().length === 0){
                    return false;
                }
            }
            else{
                return false;
            }
        }
        if(listingState.route.trim().length === 0 || key === 'route'){
            if(key === 'route'){
                if(value.trim().length === 0){
                    return false;
                }
            }
            else{
                return false;
            }
        }
        if(!listingState.location.lat || !listingState.location.lat || key === 'location'){
            if(key === 'location'){
                if(!value.lat || !value.lng){
                    return false;
                }
            }
            else{
                return false;
            }
        }

        return true;
    };

    const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const valid = checkValidity(event.target.name, event.target.value);

        setListingState({
            ...listingState, 
            location:{...listingState.location}, 
            [event.target.name]: event.target.value,
            activeStepValid: valid,
        });
    }

    const onTouch = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setTouched({...touched, [event.target.name]: true});
    }

    const onMapClick = (event: any) => {
        const valid = checkValidity('location', {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        });

        setTouched({...touched, map: true});
        setListingState({
            ...listingState, 
            location: {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            },
            activeStepValid: valid,
        });
    }

    const input = (name: any, title: string, type: string, multiline?: boolean) => {
      const key = typedKeys(listingState).filter(key=>key===name)[0];
      const tKey = typedKeys(touched).filter(key=>key===name)[0];
      
      return <TextField
              className={classes.textField}
              placeholder={title}
              label={title}
              type={type}
              multiline={multiline} 
              rows={6}
              onBlur={onTouch} 
              name={name} 
              value={listingState[key].toString()}
              onChange={onInputChange}
              error={listingState[key].toString().trim().length === 0 && touched[tKey]}
              helperText={listingState[key].toString().trim().length === 0 && touched[tKey] ? `${title} ${t('is_required')}` : ' '}
              required
            />
    }

    const onSelectionChange = (event: React.ChangeEvent<{ value: unknown, name?: string }>, name: string) => {
      if(name === 'type'){
        setListingState({
            ...listingState, 
            location:{...listingState.location}, 
            type: event.target.value as AccommodationType,
        });
      }
      else if(name === 'region'){
        setListingState({
            ...listingState, 
            location:{...listingState.location}, 
            region: event.target.value as Region,
        });
      }
    }

    const accommodationTypeSelection = <TextField
              className={classes.textField}
              label={t('type_of_accommodation')}
              name='type'
              value={listingState.type}
              onChange={(e) => onSelectionChange(e,'type')}
              helperText=" "
              select
            >
              {$enum(AccommodationType).map(key=>{
                  return <MenuItem key={key} value={key}>{t(accommodationTypeToString(key))}</MenuItem>
              })}
            </TextField>;

    const regionSelection = <TextField
              className={classes.textField}
              label={t('region')}
              name='region'
              value={listingState.region}
              onChange={(e) => onSelectionChange(e,'region')}
              helperText=" "
              select
            >
              {$enum(Region).map(key=>{
                  return <MenuItem key={key} value={key}>{t(regionToString(key))}</MenuItem>
              })}
            </TextField>;

    return <div>
        <FormGroup className={classes.root}>
            {input('title', t('title'), 'text')}
            {input('description', t('description'), 'text', true)}
            {accommodationTypeSelection}
            {regionSelection}
            {input('price', t('price'), 'text', true)}
            {input('surroundings', t('location_surroundings'), 'text', true)}
            {input('route', t('route'), 'text', true)}
        </FormGroup>
        <Typography variant='h6' className={classes.locationText}>
            {t('location')}
        </Typography>
        <div className={classes.map}>
            <MapComponent 
                accommodations={[]} 
                onClick={onMapClick} 
                markedLocation={
                    !listingState.location.lat || !listingState.location.lng ? undefined 
                    : { lat: listingState.location.lat ?? 0, lng: listingState.location.lng ?? 0 }}/>
        </div>
    </div>;
}

export default StepOne;