import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { $enum } from 'ts-enum-util';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { 
    makeStyles,
    FormGroup, 
    Grid, 
    MenuItem, 
    InputAdornment,
    TextField } from '@material-ui/core';
import { newListingState } from '../../store/new_listing_state';
import { typedKeys } from '../../utils/functions';
import { WaterType, waterTypeToString, ToiletType, toiletTypeToString } from '../../models/Types';

interface Props{
    allTouched: boolean;
}

const useStyles = makeStyles({
    root: {
        padding: 10,
    },
    textField: {
      marginBottom: 10
    },
    input: {
        marginBottom: 40,
        minWidth: 270,
    },
    row: {
        display: 'flex', 
        justifyContent: 'space-between',
    }
});

const StepTwo = (props: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [listingState, setListingState] = useRecoilState(newListingState);
    const [touched, setTouched] = useState({
        guests: props.allTouched,
        bedrooms: props.allTouched,
        beds: props.allTouched,
        floorSpaces: props.allTouched,
        kitchens: props.allTouched,
        kitchenEquipment: props.allTouched,
        bathrooms: props.allTouched,
    });
    const [t] = useTranslation('common');

    useEffect(()=>{
        setTouched({
            guests: props.allTouched,
            bedrooms: props.allTouched,
            beds: props.allTouched,
            floorSpaces: props.allTouched,
            kitchens: props.allTouched,
            kitchenEquipment: props.allTouched,
            bathrooms: props.allTouched,
        });

        setListingState({
            ...listingState,
            location: {...listingState.location},
            activeStepValid: checkValidity('guests', listingState.guests),
        });

        // eslint-disable-next-line
    }, [props.allTouched]);

    const checkValidity = (key: string, value: any) => {
        if(listingState.guests.trim().length === 0 || key === 'guests'){
            if(key === 'guests'){
                if(value.trim().length === 0){
                    return false;
                }
            }
            else{
                return false;
            }
        }
        if(listingState.bedrooms.trim().length === 0 || key === 'bedrooms'){
            if(key === 'bedrooms'){
                if(value.trim().length === 0){
                    return false;
                }
            }
            else{
                return false;
            }
        }
        if(listingState.beds.trim().length === 0 || key === 'beds'){
            if(key === 'beds'){
                if(value.trim().length === 0){
                    return false;
                }
            }
            else{
                return false;
            }
        }
        if(listingState.floorSpaces.trim().length === 0 || key === 'floorSpaces'){
            if(key === 'floorSpaces'){
                if(value.trim().length === 0){
                    return false;
                }
            }
            else{
                return false;
            }
        }
        if(listingState.kitchens.trim().length === 0 || key === 'kitchens'){
            if(key === 'kitchens'){
                if(value.trim().length === 0){
                    return false;
                }
            }
            else{
                return false;
            }
        }
        if(key === 'bathrooms'){
            if(key === 'bathrooms'){
                if(value.trim().length === 0){
                    return false;
                }
            }
            else{
                return false;
            }
        }
        if(listingState.kitchenEquipment.trim().length === 0 || key === 'kitchenEquipment'){
            if(key === 'kitchenEquipment'){
                if(value.trim().length === 0){
                    return false;
                }
            }
            else{
                return false;
            }
        }

        return true;
    };
    
    const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const valid = checkValidity(event.target.name, event.target.value);

        if(event.target.name === 'bathrooms'){
            const numOfBathrooms = Number.parseInt(event.target.value);
            let bathrooms: Array<ToiletType>;

            if(isNaN(numOfBathrooms) || numOfBathrooms < 0){
                bathrooms = [];
            }
            else {
                bathrooms = new Array(numOfBathrooms > 10 ? 10 : numOfBathrooms).fill(ToiletType.WC);
            }

            setListingState({
                ...listingState, 
                location:{...listingState.location}, 
                bathrooms: bathrooms,
                activeStepValid: false,
            });

            return;
        }

        setListingState({
            ...listingState, 
            location:{...listingState.location}, 
            [event.target.name]: event.target.value,
            activeStepValid: valid,
        });
    }

    const onSelectionChange = (event: React.ChangeEvent<{ value: unknown, name?: string }>, name: string) => {
        if(name.includes('toiletTypes')){
            const index = Number.parseInt(name.split('/')[1] ?? '0');
            const newState = [...listingState.bathrooms];
            newState[index] = event.target.value as ToiletType;

            setListingState({
                ...listingState,
                location:{...listingState.location}, 
                bathrooms: [...newState],
            });
        }
        else if(name.includes('waterType')){
            setListingState({
                ...listingState, 
                location:{...listingState.location}, 
                waterType: event.target.value as WaterType,
            });
        }
    };

    const onTouch = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setTouched({...touched, [event.target.name]: true});
    }

    const input = (name: any, title: string, type: string, multiline?: boolean) => {
      const key = typedKeys(listingState).filter(key=>key===name)[0];
      const tKey = typedKeys(touched).filter(key=>key===name)[0];
      
      return <TextField
              className={classes.textField}
              placeholder={title}
              label={title}
              type={type}
              multiline={multiline} 
              rows={6}
              onBlur={onTouch} 
              name={name} 
              value={listingState[key].toString()}
              onChange={onInputChange}
              error={listingState[key].toString().trim().length === 0 && touched[tKey]}
              helperText={listingState[key].toString().trim().length === 0 && touched[tKey] ? `${title} ${t('is_required')}` : ' '}
              required
              fullWidth
            />
    }
    const numberInput = (name: any, title: string, required = false, inputAdornment?: string ) => {
      const key = typedKeys(listingState).filter(key=>key===name)[0];
      const tKey = typedKeys(touched).filter(key=>key===name)[0];
      
      return <TextField
        className={classes.textField}
        placeholder={title}
        label={title}
        type="number"
        onBlur={onTouch} 
        name={name} 
        value={key !== 'bathrooms' ? listingState[key].toString() 
        : listingState[key].length.toString()}
        onChange={onInputChange}
        error={required && listingState[key].toString().trim().length === 0 && touched[tKey]}
        helperText={required && listingState[key].toString().trim().length === 0 && touched[tKey] ? `${title} ${t('is_required')}` : ' '}
        required={required}
        fullWidth
        InputProps={inputAdornment ? {
          endAdornment: <InputAdornment position="end">{inputAdornment}</InputAdornment>,
        } : undefined}
      />;
    }

    const toiletTypeSelection = (index: number) => {
      return <Grid item xs={6} sm={4} md={3} >
        <TextField
          className={classes.textField}
          label={`${t('toilet_type_for_bathroom')} No.${index + 1}`}
          name={'toiletTypes/' + index.toString()}
          value={listingState.bathrooms[index]}
          onChange={(e) => onSelectionChange(e, 'toiletTypes/' + index.toString())}
          helperText=" "
          select
          fullWidth
        >
          {$enum(ToiletType).map(key=>{
            return <MenuItem key={key} value={key}>{t(toiletTypeToString(key))}</MenuItem>
          })}
        </TextField>
      </Grid>;
    } 

    const bathroomTypes = () => {
        const num = listingState.bathrooms.length;

        const elements = [];

        for(let i= 0; i<num; i++){
          elements.push(toiletTypeSelection(i));
        }

        return elements;
    }
    
    const waterTypeSelection = <TextField
              className={classes.textField}
              label={t('type_of_water')}
              name='waterType'
              value={listingState.waterType}
              onChange={(e) => onSelectionChange(e,'waterType')}
              helperText=" "
              fullWidth
              select
            >
              {$enum(WaterType).map(key=>{
                  return <MenuItem key={key} value={key}>{t(waterTypeToString(key))}</MenuItem>
              })}
            </TextField>;
    
    return <FormGroup className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          {numberInput('guests', `${t('number_of')} ${t('guests')}`, true)}
        </Grid>
        <Grid item xs={12}>
          {numberInput('bedrooms', `${t('number_of')} ${t('bedrooms')}`, true)}
        </Grid>
        <Grid container spacing={isSmallScreen ? 2 : 4}>
          <Grid item xs={6}>
            {numberInput('beds', `${t('beds')}`, true)}
          </Grid>
          <Grid item xs={6}>
            {numberInput('floorSpaces', `${t('floor_spaces')}`, true)}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {numberInput('kitchens', `${t('number_of')} ${t('kitchens')}`, true)}
        </Grid>
        <Grid item xs={12}>
          {input('kitchenEquipment', `${t('kitchen_equipment')}`, 'string', true)}
        </Grid>
        <Grid item xs={12}>
          {numberInput('bathrooms', `${t('number_of')} ${t('bathrooms')}`, false)}
        </Grid>
        <Grid container xs={12} spacing={isSmallScreen ? 2 : 4}>
          {listingState.bathrooms.length > 0 && bathroomTypes()}
        </Grid>
        <Grid item xs={6}>
          {waterTypeSelection}
        </Grid>
      </Grid>
    </FormGroup>;
}

export default StepTwo;