import React from 'react';
import { makeStyles } from '@material-ui/core';

interface TabPanelProps {
  color: string,
  position: "top" | "bottom"
}

const useStyles = makeStyles({
  wave: {
    position: 'absolute',
    left: 0,
    width: '100%',
    height: '1.75rem',
    zIndex: 999,
  },
  waveTop: {
    top: '-1px'
  },
  waveBottom: {
    bottom: '-1px'
  }
});

const TabPanel = (props: TabPanelProps) => {
  const { color, position } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      { position === 'top' ? 
        <svg
          className={`${classes.wave} ${classes.waveTop}`}
          width="100%"
          height="100%"
          viewBox="0 0 1440 28"
          preserveAspectRatio="none"
        >
          <g>
            <path fill={color} d="M1434.3,0C1246.6,0.1,999.8,6.8,694,20C385.1,33.3,153.8,26.7,0,0l0,0h1440L1434.3,0z"></path>
          </g>  
        </svg>
      : 
        <svg
          className={`${classes.wave} ${classes.waveBottom}`}
          width="100%"
          height="100%"
          viewBox="0 0 1440 41"
          preserveAspectRatio="none"
        >
          <g>
            <path fill={color} d="M0,0 C153.757474,26.6788991 385.090807,33.3486239 694,20.0091743 C1002.90919,6.66972477 1251.57586,0 1440,0 L1440,40.0183486 L0,40.0183486 L0,0 Z"></path>
          </g>
        </svg>
      }
    </React.Fragment>
  );
}

export default TabPanel;