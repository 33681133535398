import React from 'react';
import { IconButton, makeStyles, Card, Theme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxHeight: '150px',
    position: 'relative',
  },
  deleteButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 99,
    backgroundColor: theme.palette.error.main,
    width: '40px',
    height: '40px',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    }
  },
  deleteIcon: {
    fontSize: '1.5rem',
    color: '#FFFFFF'
  },
  image: {
    maxHeight: '150px'
  }
}));

interface Props{
  imgURL: string;
  onFileDelete: (value: string) => void;
}

const ImageCard = (props: Props) => {
  const classes = useStyles();

  const onDelete = () => {
    props.onFileDelete(props.imgURL);
  }

  return <Card className={classes.root}>
    <IconButton
      aria-label="delete"
      className={classes.deleteButton}
      onClick={onDelete}
    >
      <DeleteIcon
        className={classes.deleteIcon}
      />
    </IconButton>
    <img src={props.imgURL} className={classes.image} alt="" />
  </Card>
}

export default ImageCard;