import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createURLFriendlyString } from '../utils/functions';
import { 
    Grid,
    CircularProgress,
    Button, 
    Typography} from '@material-ui/core';
import Accommodation from '../models/Accommodation';

// Components
import AccommodationCard from '../components/AccommodationCard';

interface Props{
  accommodations: Accommodation[],
}

const AccommodationList = (props: Props) => {
    const history = useHistory();
    const [ t ] = useTranslation('common');
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [accList, setAccList] = useState<Accommodation[]>([]);
    const [pagesCount, setPagesCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    useEffect(() => {
      setLoading(true);

      if(props.accommodations){
        setCurrentPage(0);
        createPages(props.accommodations);
        createAccommodationList(props.accommodations);
        setLoading(false);  
      }

      // eslint-disable-next-line
    }, [props.accommodations]);

    useEffect(() => {
      setLoading(true);
      createAccommodationList(props.accommodations);
      setLoadingMore(false);
      setLoading(false);
      
      // eslint-disable-next-line
    }, [currentPage]);

    const createPages = (accommodationsList: Accommodation[]) => {
      const numOfPages = Math.ceil(accommodationsList.length/itemsPerPage);
      
      setPagesCount(numOfPages);
    }

    const createAccommodationList = (accommodationsList: Accommodation[]) => {
      const endPoint = (currentPage + 1) * itemsPerPage;
      const newList = [];

      for(let i = 0; i < endPoint; i++) {
        const accommodation = accommodationsList[i];
        
        if(accommodation !== undefined) {
          newList.push(accommodation);
        }
      }

      setAccList([...newList]);
    }

    const onLoadMore = () => {
      setLoadingMore(true);
      setCurrentPage(currentPage+1);
    }
    
    const onAccommodationClick = (id: string, name: string) => {
      const url = createURLFriendlyString(name);
      history.push({
        pathname: '/accommodation/' + id + '/' + url,
        state: { id: id }
      });
    }

    const loadingScreen = <Grid container justify="center" alignItems="center">
        <CircularProgress />
      </Grid>

    return <Fragment>
        <Grid container spacing={4} style={{ marginBottom: '1rem' }}>
          {!loading
            ? accList.map((accommodation) => 
                <Grid item xs={12} md={6} xl={4} key={accommodation.id}>
                  <AccommodationCard onClick={() => onAccommodationClick(accommodation.id, accommodation.name)} accommodation={accommodation} />
                </Grid>)
            : loadingScreen
          }
          {!loading && currentPage !== pagesCount-1 
            ?<Grid item xs={12} style={{ 
                width: '100%', marginTop: 10, marginBottom: 20, display: 'flex', justifyContent: 'center'
            }}>
                {loadingMore ? <CircularProgress /> 
                    : currentPage !== (pagesCount-1) && accList.length > 0 ? <Button variant="outlined" color="primary" size="small" disableRipple onClick={onLoadMore}>
                        {t('load_more')}
                      </Button>
                      : null
                }
            </Grid>
            : null
          }
          { accList.length < 1 ? 
            <Typography variant="h3" component="p" color="textPrimary">{t('no_accommodations')}</Typography>
          : null
          }
        </Grid>
    </Fragment>;
}

export default AccommodationList;