import { atom } from "recoil";
import Accommodation from "../models/Accommodation";

export const adminState = atom<{
    accommodations: Array<Accommodation>,
    lastTs: number,
    hasMore: boolean,
}>({
    key: 'adminState',
    default: {
        accommodations: [],
        lastTs: 0,
        hasMore: true,
    }
});