import React, { Fragment, useState, useEffect } from 'react';
import { useFilePicker } from 'react-sage';
import { Typography, Card, CardActionArea, CardMedia, Button, makeStyles } from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    centerContainer: {
        width: '100%',
        height: '100%',
    },
    singleImageContainer: {
        height: 300,
        position: 'relative',
        '&::after': {
            content: 'attr(data-after-content)',
            background: 'rgba(0, 0, 0, 0.5)',
            width: '100%',
            height: '100%',
            opacity: 0,
            transition: 'opacity 0.2s',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#FFFFFF',
            fontSize: '1rem',
            textTransform: 'capitalize',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            position: 'absolute',
            top: '50%',
            left: '50%',
            zIndex: 999,
            transform: 'translate(-50%, -50%)',
        },
        '&:hover::after': {
            cursor: 'pointer',
            opacity: 1,
        },
    },
    multiImagesContainer: {
        position: 'relative',
    },
    resetImageWidth: {
        width: 'auto',
    },
    actionArea: {
        "&:hover $focusHighlight": {
            opacity: 0
        }
    },
    focusHighlight: {},
    deleteButton: {
        position: 'absolute',
        top: 5,
        right: 5,
        zIndex: 99,
    },
    addButtonBase: {
        borderStyle: 'dashed',
        borderWidth: '4px',
        position: 'relative',
        '&:hover': {
            borderStyle: 'dashed',
            borderWidth: '4px',
        }
    },
    addButtonSmall: {
        width: '200px',
        height: '150px',
        '& .MuiTypography-overline': {
            position: 'absolute',
            bottom: 25,
            lineHeight: 1,
        }
    },
    addButtonLarge: {
        width: '100%',
        height: '300px',
        '& .MuiTypography-overline': {
            position: 'absolute',
            bottom: 100,
        }
    },
    tooltip: {
        backgroundColor: '#FFAE42',
        color: '#FFFFFF',
        fontSize: '1rem'
    },
    arrow: {
        color: '#FFAE42',
    },
});

export interface Response{
    imageURLs: string[],
    files: Array<File> | null,
}

interface Props{
    multiple?: boolean;
    error?: boolean;
    imgURL?: string;
    required?: boolean;
    onFileChange: (value: Response) => void;
}

const ImageSelector = (props: Props) => {
    const { files, onClick, HiddenFileInput } = useFilePicker();
    const [invalidImage, setInvalidImage] = useState(false);
    const [t] = useTranslation('common');
    const classes = useStyles();

    useEffect(()=>{
        const validTypes = '.jpg, .jpeg, .png';
        
        let valid = true;
        files?.forEach((file)=>{
            const type = file.name.split('.')[file.name.split('.').length - 1];
            if(!validTypes.includes(type)) valid = false;
        });
        
        if(valid){
            let urls = Array<string>();
            if(files && files.length > 0){
                files.forEach((file) => {
                    urls.push(URL.createObjectURL(file));
                });
            } else {
                if( props.imgURL !== undefined) {
                    urls.push(props.imgURL);
                }
            }

            let response = {
                imageURLs: urls,
                files: files,
            }

            props.onFileChange(response);
        }

        setInvalidImage(!valid);
        
        // eslint-disable-next-line
    }, [files]);

    const singleImageSelector =
        <Card
            className={classes.singleImageContainer}
            onClick={onClick}
            data-after-content={t('replace_main_image')}
        >
            <CardActionArea 
                disableRipple 
                classes={{
                    root: classes.actionArea,
                    focusHighlight: classes.focusHighlight,
                }}
            >
                <CardMedia 
                    component="img" 
                    height={300} 
                    src={props.imgURL}
                    classes={{
                        media: classes.resetImageWidth
                    }}
                />
            </CardActionArea>
        </Card>;
    
    const addImageSelector = props.multiple ?
        <Button 
            variant="outlined"
            color={ props.error ? "secondary" : "primary" }
            className={`${classes.addButtonSmall} ${classes.addButtonBase}`}
            onClick={onClick}
        >
            <AddAPhotoIcon fontSize="large" color={ props.error ? "secondary" : "primary" } />
            {invalidImage || props.error ? <Typography color='secondary' variant="overline">
                    {invalidImage ? t('invalid_file') : t('required_image')}
                </Typography> : null}
        </Button> :
        <Button 
            variant="outlined"
            color={ props.error ? "secondary" : "primary" }
            className={`${classes.addButtonLarge} ${classes.addButtonBase}`}
            onClick={onClick}
        >
            <AddAPhotoIcon fontSize="large" color={ props.error ? "secondary" : "primary" } />
                {invalidImage || props.error ? <Typography color='secondary' variant="overline">
                    {invalidImage ? t('invalid_file') : t('required_image')}
                </Typography> : null}
        </Button>;
        
    const hasImages = props.imgURL !== undefined ? true : false;

    return <Fragment>
                {hasImages 
                    ? singleImageSelector
                    : addImageSelector
                }
                <HiddenFileInput accept=".jpg, .jpeg, .png" multiple={props.multiple} />
    </Fragment>
}

ImageSelector.defaultProps = {
    required: false,
}

export default ImageSelector;